import React from 'react'
import GalleryItem from './galleryItem'

const Gallery = ({ posts }) => {
  return (
    <div className="gallery">
      {posts.edges.map(
        (item) =>
          item.node.localImage && (
            <GalleryItem
              image={item.node.localImage.childImageSharp.gatsbyImageData}
              caption={item.node.caption}
              id={item.node.id}
            />
          )
      )}
    </div>
  )
}

export default Gallery
