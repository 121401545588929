import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Gallery from '../components/gallery'
import BikeLogos from '../components/bikelogos'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const HeaderImg = styled.div`
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    color: transparent;
  }

  @media (max-width: 720px) {
    height: 280px;
  }

  .frontpage-hero {
    filter: blur(2px);
    width: 100%;
    height: 100%;
    opacity: 0.5;
    object-fit: cover;
  }

  .frontpage-logo {
    position: absolute;
    margin-top: 80px;
    width: 70%;
    max-width: 800px;
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout seo="Cykelbutik &amp; Cykelverkstad i Åre">
    <HeaderImg>
      <h1>Bike Republic - Cykelbutik &amp; Cykelverkstad i Åre</h1>

      <StaticImage
        src="../images/bike-republic-hero.jpg"
        alt="Bike Republic"
        className="frontpage-hero"
      />
      <StaticImage
        src="../images/br-logo.png"
        alt="Bike Republic"
        className="frontpage-logo"
        backgroundColor="transparent"
        placeholder="none"
      />
    </HeaderImg>
    <Gallery posts={data.allInstagramContent} />
    <BikeLogos />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query InstagramPosts {
    allInstagramContent(sort: { fields: timestamp, order: DESC }, limit: 12) {
      edges {
        node {
          id
          caption
          localImage {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 800
                height: 800
              )
            }
          }
        }
      }
    }
  }
`
