import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const Bikelogos = styled.div`
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 4rem;

  .bikelogo {
    width: 70px;
    margin: 1rem;
    vertical-align: middle;
  }

  @media (max-width: 720px) {
    .bikelogo {
      width: 50px;
      margin: 0.5rem;
    }
  }
`

const BikeLogos = () => {
  return (
    <Bikelogos>
      <StaticImage
        src="../images/bikelogo/dharco.png"
        alt="Dharco - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/rockymountain.png"
        alt="Rocky Mountain - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/troyleedesigns.png"
        alt="Troy Lee Designs - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/maxxis.png"
        alt="Maxxis - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/spawn.png"
        alt="Spawn - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/shimano.png"
        alt="Shimano - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/fox.png"
        alt="Fox - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/ohlins.png"
        alt="Öhlins - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/rockshox.png"
        alt="Rock Shox - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/marzocchi.png"
        alt="Marzocchi - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/100percent.png"
        alt="100% - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/bell.png"
        alt="Bell - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/crankbrothers.png"
        alt="Crank Brothers - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/ergon.png"
        alt="Ergon - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/fasthouse.png"
        alt="Fasthouse - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/giro.png"
        alt="Giro - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/melon.png"
        alt="Melon - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/michelin.png"
        alt="Michelin - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/renthal.png"
        alt="Renthal - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/dt-swiss.png"
        alt="DT-Swiss - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/magura.png"
        alt="Magura - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/title.png"
        alt="Title - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/oneup.png"
        alt="Oneup - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/muc-off.png"
        alt="Muc-Off - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/pirelli.png"
        alt="Pirelli - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/sram.png"
        alt="SRAM - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/canondale.png"
        alt="Canondale - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/cl-brakes.png"
        alt="CL Brakes - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/continental.png"
        alt="Continental - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/cushcore.png"
        alt="Cushcore - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/ext.png"
        alt="EXT Racing Shox - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/fiveten.png"
        alt="Fiveten - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/galfer.png"
        alt="Galfer - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/hope.png"
        alt="Hope - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/industrynine.png"
        alt="Industry Nine - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/leatt.png"
        alt="Leatt - Bike Republic"
        className="bikelogo"
      />
      <StaticImage
        src="../images/bikelogo/reverse.png"
        alt="Reverse Components - Bike Republic"
        className="bikelogo"
      />
    </Bikelogos>
  )
}

export default BikeLogos
